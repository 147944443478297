import { useQuery } from "@tanstack/react-query"
import {
  ActivityDefinition,
  Bundle,
  ChargeItemDefinition,
  codeableConceptAsString,
  Coding,
  getResources,
  isChargeItemDefinition,
  Medication,
  MedicationKnowledge,
  MedicationRequest,
  RequestGroup,
  RequestGroupActionArrayActionArray,
} from "fhir"
import pluralize from "pluralize"
import { useMemo } from "react"

import { useClient } from "api"
import { ChargeItemDefinitions, useCIDQueryFunction } from "commons"
import { MEDICATION_PRODUCT_TYPE } from "data"
import { getBasePrice, getCidIdentifier, getCommonCode, getMedCodes, isMrMedication, isMrProcedure } from "utils"

import { cpoeOrdersKeys } from "../query-keys"
import { ACTION_GROUP_CODES, CpoeRequest } from "../types"
import { checkIsActionType } from "../utils"

const useCpoeOrders = (organizationId: string, patientId: string, ordersCount?: number) => {
  const { operationRequest, transaction } = useClient()
  const queryKey = cpoeOrdersKeys.withPatientId(patientId)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey,
    enabled: !!ordersCount,
    queryFn: async () => {
      const rg = await operationRequest<RequestGroup>({
        endpoint: `Patient/${patientId}/cpoe`,
        method: "POST",
        operation: "dwim",
      })

      if (!rg || !rg.action?.[0].action) {
        return {
          nutraceuticalActions: [],
          medicationRequests: [],
          medicationKnowledges: [],
          chargeItemDefinitions: {} as ChargeItemDefinitions,
          activityDefinition: undefined,
          requestGroup: undefined,
        } as {
          nutraceuticalActions: RequestGroupActionArrayActionArray[]
          medicationRequests: MedicationRequest[]
          medicationKnowledges: MedicationKnowledge[]
          chargeItemDefinitions: ChargeItemDefinitions
          activityDefinition: ActivityDefinition | undefined
          requestGroup: RequestGroup | undefined
        }
      }

      const nutraceuticalActions = rg.action[0].action.filter(
        ({ resource, code }) =>
          resource?.resourceType === "MedicationRequest" &&
          checkIsActionType({ codeableConcept: code, type: ACTION_GROUP_CODES.NUTRA }),
      )

      const medActionIds = nutraceuticalActions.map((req) => req.resource?.id)
      const activityDefinitionReference = rg.action[1].resource

      const filters = new URLSearchParams({
        _query: "cpoe-medication-data",
        _patient: patientId,
        _activityDefinition: activityDefinitionReference?.id as string,
        _medicationRequests: medActionIds.join(","),
      })

      const reqBundle: Bundle = {
        resourceType: "Bundle",
        type: "transaction",
        entry: [
          {
            request: {
              method: "GET",
              url: `/MedicationRequest?${filters}`,
            },
          },
        ],
      }

      const respBundle = await transaction(reqBundle)

      const medBundle = (respBundle as Bundle).entry?.[0]?.resource as Bundle

      const medicationRequests = getResources<MedicationRequest>(medBundle, "MedicationRequest")
      const medicationKnowledges = getResources<MedicationKnowledge>(medBundle, "MedicationKnowledge")
      const activityDefinition = getResources<ActivityDefinition>(medBundle, "ActivityDefinition")?.[0]

      const medCodes = getMedCodes({ meds: medicationRequests, withQty: true })
      const codes: { billToPracticeOrInsuranceCIDs: Coding[]; billToPatientCIDs: Coding[] } = {
        billToPatientCIDs: [],
        billToPracticeOrInsuranceCIDs: medCodes?.length ? [...medCodes] : [],
      }

      const chargeItemDefinitions = await getChargeItemDefinitions(organizationId, codes)

      return {
        medicationRequests,
        medicationKnowledges,
        chargeItemDefinitions,
        activityDefinition,
        requestGroup: rg,
        nutraceuticalActions,
      }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, patientId } },
  })

  // Link RG actions with original MR or SR
  const { cpoeRequests, activeRequests, activityDefinition, shippingMethods, isOnlyDfo, discounts, isOnlyMedication } =
    useMemo(() => {
      let cpoeRequests: CpoeRequest[] = []

      // Add nutraceutical requests
      if (data?.nutraceuticalActions) {
        cpoeRequests = data.nutraceuticalActions.reduce((resultArray, action) => {
          const mr = data.medicationRequests.find((mr) => mr.id === action.resource?.id)
          const mrCode = getCommonCode(mr?.medication?.CodeableConcept?.coding)
          const mk = data.medicationKnowledges.find((mk) => getCommonCode(mk.code?.coding) === mrCode)
          const isRX = mr?.category?.[0]?.coding?.some(({ code }) => code === MEDICATION_PRODUCT_TYPE.RX)
          const doseForm = isRX ? mk?.doseForm?.coding?.[0]?.display : undefined
          const packaging = isRX ? mk?.packaging?.type?.coding?.[0]?.display : undefined

          if (mr) {
            const newValue: CpoeRequest = {
              resource: {
                ...action,
                timing: { ...action.timing, dateTime: new Date().toISOString() },
              },
              display:
                `${
                  (mr.contained?.[0] as Medication)?.code?.text ??
                  codeableConceptAsString(mr.medication?.CodeableConcept)
                } (${mr.dispenseRequest?.quantity?.value} ${pluralize(mr.dispenseRequest?.quantity?.unit ?? "", mr.dispenseRequest?.quantity?.value ?? 1)}
    ${mk?.ingredient?.length === 1 && mk?.ingredient[0]?.strength?.numerator?.unit ? ` / ${!isRX ? (mk?.ingredient[0]?.strength?.numerator?.value ?? "") : ""}${mk.ingredient[0].strength.numerator.unit}` : ""})` ??
                "Unspecified",
              medicationData: {
                manufacturer: mk?.manufacturer?.display ?? "Unspecified",
                medicationRequest: mr,
                dispenseRequest: mr.dispenseRequest,
                isDfo: mr.dispenseRequest?.initialFill?.isDfo,
                isProcedure: isMrProcedure(mr),
                isMedication: isMrMedication(mr),
                doseForm,
                packaging,
              },
              quantity: mr.dispenseRequest?.quantity?.value ?? 1,
              unitPrice: getBasePrice(
                data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs?.[
                  getCidIdentifier(mrCode, mr?.dispenseRequest?.quantity?.value)
                ]?.propertyGroup?.[0].priceComponent,
              )?.amount ?? {
                currency: "USD",
                value: 0,
              },
              type: ACTION_GROUP_CODES.NUTRA,
            }

            resultArray.push(newValue)
          }

          return resultArray
        }, cpoeRequests)
      }

      const activeRequests = cpoeRequests?.filter((req) => req.resource.code?.[0].coding?.[0].code === "activate") ?? []

      const shippingMethods = data?.requestGroup?.contained?.filter(
        (res) =>
          isChargeItemDefinition(res) &&
          res.code?.coding?.some((coding) => coding.system === "http://chartedhealth.com/fhir/shipping-method"),
      ) as ChargeItemDefinition[] | undefined

      const discounts = data?.requestGroup?.contained?.filter(
        (res) =>
          isChargeItemDefinition(res) &&
          res.code?.coding?.some((coding) => coding.system === "http://chartedhealth.com/fhir/payment/discount"),
      ) as ChargeItemDefinition[] | undefined

      const isOnlyDfo =
        cpoeRequests
          ?.filter((req) => req.type === ACTION_GROUP_CODES.PHARMA || req.type === ACTION_GROUP_CODES.NUTRA)
          .every((med) => med.medicationData?.isDfo) ?? false

      const isOnlyMedication =
        cpoeRequests
          ?.filter((req) => req.type === ACTION_GROUP_CODES.PHARMA || req.type === ACTION_GROUP_CODES.NUTRA)
          .every((med) => med.medicationData?.isMedication) ?? false

      return {
        cpoeRequests,
        activityDefinition: data?.activityDefinition,
        activeRequests,
        shippingMethods,
        isOnlyDfo,
        isOnlyMedication,
        discounts,
      }
    }, [data])

  return {
    cpoeRequests,
    activeRequests,
    requestGroup: data?.requestGroup,
    activityDefinition,
    requestShippingMethods: shippingMethods,
    requestDiscounts: discounts,
    isLoading: isLoading,
    isFetching,
    isError,
    isOnlyDfo,
    isOnlyMedication,
  }
}

export { useCpoeOrders }
