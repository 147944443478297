import { Duration, RequestGroup, RequestGroupActionArrayActionArray, ResourceObject } from "fhir"
import { Skeleton } from "primereact/skeleton"
import { useMemo } from "react"

import { Button, SkeletonLoader } from "commons"
import { cpoeActions } from "data"

import { useUpdateMedicationRequestsDispenseInterval } from "medication-requests/hooks/useUpdateMedicationRequestsDispenseInterval"
import { useCPOERequestsContext, useExtrasPrices, useUpdateRG } from "../../hooks"
import { updateRequestStatus } from "../../utils"
import OrderList from "../OrderList"

const ShoppingCartSummary = ({ isLoading, onDelete, onCheckout, mrToDelete }: Props) => {
  const {
    selectedRequests,
    isLoadingRequests,
    subtotal,
    requestGroup,
    activeRequestsCount,
    activeRequestReferences,
    updateSelectedRequests,
  } = useCPOERequestsContext()
  const { updateCpoeRG, isUpdatingRG } = useUpdateRG(onCheckout)

  const { medsShippingMethods, nutraShippingMethods } = useExtrasPrices(activeRequestReferences)

  const shippingMethods = useMemo(
    () => [...(nutraShippingMethods ?? []), ...(medsShippingMethods ?? [])],
    [nutraShippingMethods, medsShippingMethods],
  )

  const { updateMRDispenseInterval } = useUpdateMedicationRequestsDispenseInterval()

  const onChangeMedRequest = (field: string, value: boolean | Duration, id?: string) => {
    const actionIndex = selectedRequests.findIndex(({ resource }) => resource.resource?.id === id)

    let newAction = { ...selectedRequests[actionIndex] }

    if (field === "dispenseInterval") {
      if (newAction.medicationData?.dispenseRequest) {
        newAction.medicationData.dispenseRequest.dispenseInterval = value as Duration
        if (newAction.medicationData.dispenseRequest.dispenseInterval.code === "mo") {
          newAction.medicationData.dispenseRequest = {
            ...newAction.medicationData.dispenseRequest,
            numberOfRepeatsAllowed: undefined,
          }
        } else {
          newAction.medicationData.dispenseRequest = {
            ...newAction.medicationData.dispenseRequest,
            numberOfRepeatsAllowed: 0,
          }
        }
        // Update MR dispense interval
        updateMRDispenseInterval({
          mrId: newAction.medicationData.medicationRequest?.id as string,
          dispenseRequest: newAction.medicationData.dispenseRequest,
        })
      }
    }

    if (field === "status") {
      newAction = updateRequestStatus(newAction, value === true ? cpoeActions.activateMR : cpoeActions.deactivateMR)
    }

    updateSelectedRequests([
      ...selectedRequests.slice(0, actionIndex),
      newAction,
      ...selectedRequests.slice(actionIndex + 1),
    ])
  }

  const handleCheckout = () => {
    const newConfigAction = {
      ...requestGroup?.action?.[0],
      action: [...selectedRequests.map((req) => req.resource as RequestGroupActionArrayActionArray)],
    }

    const updatedPaymentConfigActions = [...(requestGroup?.action?.[1]?.action ?? [])].reduce((prev, action) => {
      const isFee = action.code?.some(({ coding }) => coding?.some(({ code }) => code === "add-fee"))

      if (isFee) {
        /* Check if this fee is one of the fees for active requests */
        const isInShipping = shippingMethods.some(({ id }) => action.resource?.localRef === id)
        return isInShipping ? [...prev, action] : [...prev]
      } else return [...prev, action]
    }, [] as RequestGroupActionArrayActionArray[])

    /* Clean all contained items from RG that can't be matched with items in payment config */
    const updatedRGContained = [
      ...((requestGroup?.contained as ResourceObject[] | undefined)?.filter(({ id }) =>
        updatedPaymentConfigActions.some(({ resource }) => resource?.localRef === id),
      ) ?? []),
    ]

    const newPaymentAction = {
      ...requestGroup?.action?.[1],
      action: [...updatedPaymentConfigActions],
    }

    const updatedRG = {
      ...requestGroup,
      contained: [...updatedRGContained],
      action: [newConfigAction, newPaymentAction, ...(requestGroup?.action?.slice(2) ?? [])],
    } as RequestGroup

    updateCpoeRG(updatedRG)
  }

  return (
    <>
      <div className="flex flex-col w-full overflow-hidden relative">
        {isLoadingRequests ? (
          <SkeletonLoader loaderType="two-lines" repeats={2} containerClassName="grow" />
        ) : (
          <OrderList
            editable={true}
            requests={selectedRequests}
            onChangeMed={onChangeMedRequest}
            onDelete={onDelete}
            mrToDelete={mrToDelete}
          />
        )}

        <div className="border-t border-gray-200 p-6 sticky bottom-0 w-full bg-white">
          <div className="flex justify-between text-sm font-medium text-gray-900">
            <p>Subtotal</p>
            <div>{isLoading ? <Skeleton className="w-12" /> : `$${subtotal.toFixed(2)}`}</div>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout</p>
          <div className="mt-6">
            <Button
              className="w-full px-6 py-3 text-base justify-center"
              label="Checkout"
              loading={isLoading || isUpdatingRG}
              onClick={handleCheckout}
              disabled={activeRequestsCount === 0}
            />
          </div>
        </div>
      </div>
    </>
  )
}

type Props = {
  onDelete(mrId: string): void
  isLoading: boolean
  onCheckout(): void
  mrToDelete?: string
}

export { ShoppingCartSummary }
