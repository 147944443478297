import { faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { Duration, isCarePlan, isPractitionerRole } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"

import { Button, CardListItem } from "commons"
import { dispenseInterval, DispenseIntervalOption } from "commons/meds"
import { cpoeActions } from "data"
import { getMoneyCurrencyAlt } from "utils"

import { CpoeRequest } from "../types"
import "./OrderListItem.css"

const OrderListItem = ({ medRequest, onChange, editable, onDelete, mrToDelete }: Props) => {
  const dispenseTemplate = (option: DispenseIntervalOption) => `Dispense ${(option?.label ?? "").toLocaleLowerCase()}`
  const { medicationRequest, manufacturer, doseForm, packaging, isDfo, dispenseRequest } =
    medRequest.medicationData ?? {}

  const planBased = medicationRequest?.basedOn?.some(isCarePlan) ?? false

  const head = (
    <div className="flex justify-between w-full items-start gap-1 h-fit">
      <div className="flex flex-1 items-center gap-2">
        {editable && (
          <Checkbox
            name="status"
            checked={medRequest.resource.code?.[0]?.coding?.[0]?.code === cpoeActions.activateMR.code}
            onChange={(e) => onChange?.(e.target.name, !!e.checked)}
          />
        )}
        <div className="flex flex-1 flex-col">
          <div className="font-semibold flex line-clamp-2 text-wrap text-ellipsis">{medRequest.display}</div>
          <div className="line-clamp-1 space-x-1 text-gray-400 text-sm text-ellipsis">
            {!!manufacturer && <span title="Manufacturer">{manufacturer}</span>}
            {!!doseForm && <span title="Drug Form">{doseForm}</span>}
            {!!packaging && <span title="Packaging">Pkg: {packaging}</span>}
          </div>

          {isPractitionerRole(medicationRequest?.recorder) && (
            <span className="text-orange-700 text-xs truncate">Requested by practitioner</span>
          )}
        </div>
      </div>
      <div className="flex flex-1 min-w-[50%] max-w-[50%] gap-2">
        <div className="flex flex-wrap xl:flex-nowrap items-center gap-1 grow justify-evenly">
          {!isDfo && (
            <span className="inline-flex text-gray-400" title={editable ? "Click to change dispense interval" : ""}>
              <Dropdown
                name="dispenseInterval"
                options={dispenseInterval}
                optionLabel="label"
                optionValue="value"
                className="p-inputtext-sm order"
                valueTemplate={dispenseTemplate}
                value={dispenseRequest?.dispenseInterval}
                disabled={!editable}
                onChange={(e) => onChange?.(e.target.name, e.target.value)}
              />
            </span>
          )}
          <div className="font-bold text-gray-600 text-right inline-flex">
            {getMoneyCurrencyAlt(medRequest?.unitPrice?.currency)}
            {(medRequest?.unitPrice?.value ?? 0).toFixed(2)}
          </div>
        </div>
        {!!onDelete && !planBased && (
          <div className="flex justify-end items-center py-1" title="Delete">
            <Button
              icon={faTrashCan}
              loading={medRequest.resource.resource?.id === mrToDelete}
              iconSize="1x"
              className="icon-only ring-1"
              onClick={() => {
                onDelete(medRequest.resource.resource?.id as string)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )

  return <CardListItem className="px-2 border-none order-item" contentHeader={head} />
}

type Props = {
  medRequest: CpoeRequest
  onChange?(field: string, value: boolean | Duration): void
  editable: boolean
  onDelete?(requestId: string): void
  mrToDelete?: string
}

export { OrderListItem }
