import { faHexagonExclamation, faInfoCircle, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address, addressStringify } from "fhir"
import { getAddressByType, getHomeAddress } from "fhir/utils"
import { Checkbox } from "primereact/checkbox"
import { Tooltip } from "primereact/tooltip"
import { useEffect, useState } from "react"

import { AddressField, Button, DialogFormContainer } from "commons"
import { emptyAddress } from "data"
import { useCPOEContext } from "orders"
import { useUpdatePatientShippingAddress } from "patient"
import { useLoginContext } from "security"
import { getAddressSchema, isPoBoxAddress } from "utils"

const OrdersShippingAddress = () => {
  const { loggedInPatient } = useLoginContext()
  const { setCheckoutState, checkoutState } = useCPOEContext()
  const { updateShippingAddress, isUpdating } = useUpdatePatientShippingAddress(() => {
    setShowEdit(false)
    setUseHome(false)
  })

  const homeAddress = getHomeAddress(loggedInPatient.address)
  const shippingAddress = getAddressByType(loggedInPatient.address, "postal") ?? homeAddress

  const [showEdit, setShowEdit] = useState(false)
  const [useHome, setUseHome] = useState(false)

  useEffect(() => {
    setCheckoutState({ ...checkoutState, updatingAddress: isUpdating })
  }, [isUpdating])

  const onSubmit = (address: Address) => {
    updateShippingAddress({ address, patient: loggedInPatient })
  }

  return (
    <>
      <div id="shipping-address-section" className="flex flex-col">
        <div className="flex justify-between items-baseline">
          <h2 className="text-lg font-medium text-gray-900 mb-4 inline-flex">Shipping address</h2>
          <Button buttonStyle="default" label="Edit" onClick={() => setShowEdit(true)} />
        </div>
        <span className="text-gray-500 text-sm">
          {isPoBoxAddress(shippingAddress) && (
            <>
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
              />
              <span className="cursor-pointer tooltiped mr-4">
                <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
              </span>
            </>
          )}
          {addressStringify(shippingAddress)}
        </span>
        {!shippingAddress && (
          <small className="p-error">
            <FontAwesomeIcon icon={faHexagonExclamation} className="mr-1" />
            Please specify a shipping address to proceed
          </small>
        )}
      </div>
      <DialogFormContainer
        title="Edit shipping address"
        showForm={showEdit}
        initialValue={shippingAddress ?? { ...emptyAddress, type: "postal" }}
        validationSchema={getAddressSchema()}
        onSubmit={onSubmit}
        onCancel={() => {
          setShowEdit(false)
          setUseHome(false)
        }}
      >
        {({ setValues, initialValues }) => {
          const onChange = (value: boolean) => {
            const { use, ...address } = homeAddress as Address
            setValues(value ? { ...address, type: "postal" } : initialValues)
            setUseHome(value)
          }
          return (
            <>
              {homeAddress && (
                <div className="flex items-center text-sm gap-1 mt-1">
                  <Checkbox
                    inputId="usehome"
                    onChange={(e) => onChange(e.checked ?? false)}
                    checked={useHome}
                    value="Use home address"
                  />
                  <label htmlFor="usehome" className="text-gray-500 text-sm cursor-pointer" title="Use home address">
                    <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
                    {addressStringify(homeAddress)}
                  </label>
                </div>
              )}
              <AddressField context="Address" />
            </>
          )
        }}
      </DialogFormContainer>
    </>
  )
}

export { OrdersShippingAddress }
